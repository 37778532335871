<template>
  <div class="vg_wrapper">
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane :key="'first'" label="单据信息" name="first">
        <childTab1 ref="childTab1" @isShow="isShow" />
      </el-tab-pane>
      <el-tab-pane :key="'second'" :lazy="true" label="审批流转" name="second">
        <childTab2 :isShow="isSho" @draftState="draftState" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ProductDesignGroupEditMain from '@/views/DevelopManagement/ProductDesignGroupManage/TabChild/ProductDesignGroupEditMain.vue';
import ProductDesignGroupEditAppr from '@/views/DevelopManagement/ProductDesignGroupManage/TabChild/ProductDesignGroupEditAppr.vue';

export default {
  name: 'InformEdit',
  components: {
    childTab1: ProductDesignGroupEditMain,
    childTab2: ProductDesignGroupEditAppr
  },
  data() {
    return {
      activeName: 'first',
      isSho: true
    };
  },
  created() {},
  methods: {
    isShow(val) {
      this.isSho = val;
    },
    draftState() {
      this.$refs.childTab1.initData();
    }
  }
};
</script>

<style lang="scss" scoped></style>
