<template>
  <div class="vg_wrapper">
    <EditHeader
      :btn="btn"
      :isShow="isShow"
      :special="true"
      :strForm="{ status: formStatus }"
      @closeEdit="closeEdit"
      @openEdit="openEdit"
      @refresh="initData"
      @submit="submit"
    ></EditHeader>
    <DynamicForm
      ref="dynamicForm"
      :form-list="mainForm.formProperties"
      :is-show="isShow"
      :split="mainForm.split"
      :total-width="mainForm.totalWidth"
      class="dynamicForm"
      v-bind="$attrs"
    />
    <div class="flexV" style="width: 100%; margin-top: 100px">
      <div style="width: 40%; padding-right: 20px">
        <el-button :disabled="isShow" class="vg_mb_8" size="small" type="primary" @click="relatedProductDesignForm()">关联产品设计单</el-button>
        <el-button :disabled="isShow" class="vg_mb_8" size="small" type="danger" @click="delRow1()">删除关联</el-button>
        <DynamicUTable
          ref="dynamicUTable"
          :columns="productDesignGroupSubTableProperties"
          :is-show="isShow"
          :need-pagination="false"
          :tableData="computedSubTableData"
          :tableSelectionChange="val => (subSelections = val)"
        />
      </div>
      <div style="width: 60%">
        <div class="flexV vg_mb_8">
          <el-button :disabled="isShow" size="small" type="success" @click="importManuscript()">导入稿件</el-button>
          <el-upload
            ref="upload"
            :action="getUploadUrl"
            :before-upload="beforeUpload"
            :data="{ form_id: form_id, perm_id: perm_id }"
            :on-success="uploadSuccess"
            :show-file-list="true"
            class="flexHV vg_ml_8 vg_mr_8"
            multiple
          >
            <el-button :disabled="isShow" size="small" type="primary">上传稿件</el-button>
          </el-upload>
          <el-button :disabled="isShow" size="small" type="danger" @click="delRow2()">删除</el-button>
        </div>
        <DynamicUTable
          ref="dynamicUTable2"
          :columns="productDesignGroupFileSubTableProperties"
          :is-show="isShow"
          :need-pagination="false"
          :tableData="computedSubFileTableData"
          :tableSelectionChange="val => (subFileSelections = val)"
        >
          <template v-slot:operation="scope">
            <div class="flexHV">
              <el-link :disabled="isShow" class="vg_pointer" type="success" @click="clickDownload(scope.row)">下载</el-link>
              <el-link :disabled="isShow" class="vg_ml_8 vg_pointer" type="danger" @click="$set(scope.row, 'destroy_flag', 1)">删除</el-link>
              <el-link v-if="imageArr.indexOf(scope.row.docu_suffix) !== -1" :disabled="false" class="vg_ml_8 vg_pointer" @click="clickView(scope.row)"
                >查看
              </el-link>
              <el-link v-if="'pdf' === scope.row.docu_suffix" :disabled="false" class="vg_ml_8 vg_pointer" @click="openPDF(scope.row.docu_url)">查看</el-link>
              <el-link v-if="officeFileSuffixList.includes(scope.row.docu_suffix)" class="vg_ml_8 vg_pointer" @click="openOffice(scope.row.docu_url)"
                >查看
              </el-link>
            </div>
          </template>
        </DynamicUTable>
      </div>
    </div>
    <el-dialog :visible.sync="productDesignGroupDialog1Visible" title="关联产品设计单" width="60%" @opened="getProductDesignGroupDialog1Data">
      <el-button :disabled="isShow" class="vg_mb_8" size="small" type="primary" @click="productDesignGroupDialog1Confirm()">确认关联</el-button>
      <el-button :disabled="isShow" class="vg_mb_8 vg_mr_8" size="small" type="info" @click="productDesignGroupDialog1Refresh()">刷新</el-button>
      <el-date-picker
        v-model="dialog1DataRange"
        class="vg_mr_8 topDateRangeSearch"
        end-placeholder="录入结束日期"
        range-separator="至"
        size="small"
        start-placeholder="录入开始日期"
        type="daterange"
      />
      <el-button :disabled="isShow" class="vg_mb_8 vg_mr_8" size="small" type="primary" @click="getProductDesignGroupDialog1Data()">查询</el-button>
      <DynamicUTable
        ref="productDesignGroupDialog1"
        :columns="productDesignGroupDialog1"
        :need-fixed-height="true"
        :need-pagination="true"
        :need-search="true"
        :table-data="productDesignGroupDialog1TableData"
        :tableSelectionChange="val => (productDesignGroupDialog1Selections = val)"
        :total-page="productDesignGroupDialog1Total"
        @getTableData="getProductDesignGroupDialog1Data"
      />
    </el-dialog>
    <el-dialog :visible.sync="productDesignGroupDialog2Visible" title="导入委托设计单稿件" width="60%" @opened="getProductDesignGroupDialog2Data">
      <el-button :disabled="isShow" class="vg_mb_8" size="small" type="primary" @click="productDesignGroupDialog2Confirm()">确认导入</el-button>
      <el-button :disabled="isShow" class="vg_mb_8 vg_mr_8" size="small" type="info" @click="productDesignGroupDialog2Refresh()">刷新</el-button>
      <el-date-picker
        v-model="dialog2DataRange"
        class="vg_mr_8 topDateRangeSearch"
        end-placeholder="上传结束日期"
        range-separator="至"
        size="small"
        start-placeholder="上传开始日期"
        type="daterange"
      />
      <el-button :disabled="isShow" class="vg_mb_8 vg_mr_8" size="small" type="primary" @click="getProductDesignGroupDialog2Data()">查询</el-button>
      <DynamicUTable
        ref="productDesignGroupDialog2"
        :columns="productDesignGroupDialog2"
        :need-fixed-height="true"
        :need-search="true"
        :need-pagination="false"
        :table-data="productDesignGroupDialog2TableData"
        :tableSelectionChange="val => (productDesignGroupDialog2Selections = val)"
        @getTableData="getProductDesignGroupDialog2Data"
      >
        <template v-slot:operation="scope">
          <div class="flexHV">
            <el-link v-if="imageArr.includes(scope.row.docu_suffix)" :disabled="false" class="vg_ml_8 vg_pointer" @click="clickView(scope.row)">查看</el-link>
            <el-link v-if="'pdf' === scope.row.docu_suffix" :disabled="false" class="vg_ml_8 vg_pointer" @click="openPDF(scope.row.docu_url)">查看</el-link>
            <el-link v-if="officeFileSuffixList.includes(scope.row.docu_suffix)" class="vg_ml_8 vg_pointer" @click="openOffice(scope.row.docu_url)"
              >查看
            </el-link>
          </div>
        </template>
      </DynamicUTable>
    </el-dialog>
    <el-dialog :visible.sync="imageDialogVisible" class="showImg" title="图片查看" width="50%">
      <el-row class="vd_dis">
        <el-image :src="showImgUrl" class="showImg" fit="contain"></el-image>
      </el-row>
    </el-dialog>
    <!--<el-row class="vg_mt_16 vd_bortop">-->
    <!--  <el-col>-->
    <!--    <inputUser :isCookie="true" :stffForm="stffForm" ref="userData"></inputUser>-->
    <!--  </el-col>-->
    <!--</el-row>-->
  </div>
</template>

<script>
import inputUser from '@/views/component/inputUser';
import DynamicForm from '@/components/DynamicFormV2.vue';
import { cloneDeep, debounce } from 'lodash';
import {
  imageArr,
  officeFileSuffixList,
  productDesignGroupDialog1,
  productDesignGroupDialog2,
  productDesignGroupFileSubTableProperties,
  productDesignGroupMainFormProperties,
  productDesignGroupSubTableProperties
} from '@/views/DevelopManagement/ProductDesignGroupManage/productDesignGroup';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { productDesignGroupAPI } from '@api/modules/productDesignGroup';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { requiredReminderAndUGrid } from '@assets/js/domUtils';
import AddHeader from '@/views/component/addHeader.vue';
import { changeStaffForm, getDataCenterByPermId, setTime } from '@api/public';
import helper from '@assets/js/helper';
import UrlEncode from '@assets/js/UrlEncode';
import EditHeader from '@/views/component/editHeaderLine.vue';
import { arrayDeRepeat, getArrayIds } from '@assets/js/arrayUtils';

export default {
  name: 'productDesignGroupEditMain',
  components: {
    EditHeader,
    AddHeader,
    SearchTable,
    DynamicUTable,
    DynamicForm,
    inputUser
  },
  data() {
    return {
      isShow: true,
      formStatus: 0,
      btn: {},
      stffForm: {},
      splitForm: {},
      mainForm: {
        split: 8,
        totalWidth: 24,
        formProperties: cloneDeep(productDesignGroupMainFormProperties)
      },
      productDesignGroupSubTableProperties: cloneDeep(productDesignGroupSubTableProperties),
      subTableData: [],
      subSelections: [],
      productDesignGroupFileSubTableProperties: cloneDeep(productDesignGroupFileSubTableProperties),
      subFileTableData: [],
      subFileSelections: [],
      productDesignGroupDialog1: cloneDeep(productDesignGroupDialog1),
      productDesignGroupDialog1Visible: false,
      productDesignGroupDialog1TableData: [],
      productDesignGroupDialog1Selections: [],
      productDesignGroupDialog1Total: 0,
      dialog1DataRange: [],
      productDesignGroupDialog2: cloneDeep(productDesignGroupDialog2),
      productDesignGroupDialog2Visible: false,
      productDesignGroupDialog2TableData: [],
      productDesignGroupDialog2Selections: [],
      dialog2DataRange: [],
      uploadingFiles: [],
      successFlag: 0,
      beforeFlag: 0,
      imageArr: imageArr,
      imageDialogVisible: false,
      showImgUrl: '',
      officeFileSuffixList: officeFileSuffixList,
      designTeam_id: '',
      perm_id: '',
      form_id: ''
    };
  },
  computed: {
    computedSubTableData() {
      return this.subTableData.filter(x => x.destroy_flag !== 1);
    },
    computedSubFileTableData() {
      return this.subFileTableData.filter(x => x.destroy_flag !== 1);
    },
    getUploadUrl() {
      return '/api/designTeam/upload_documents';
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      const [valueListObj, valueListArr] = await getDataCenterByPermId({ id: 10004, type: 'valueListAndDefault', otherColumn: 'param2' });
      this.productDesignGroupSubTableProperties.find(x => x.label === '产品类别').subItem.options = valueListObj;
      let find = this.productDesignGroupDialog1.find(x => x.label === '产品类别');
      find.options = valueListArr;
      find.subItem.options = valueListObj;
      this.getFormData();
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    submit() {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          this.$refs.dynamicForm.$refs.form.validate((valid, object) => {
            if (valid) {
              this.saveInfo();
            } else {
              let tempStr = '';
              requiredReminderAndUGrid(object, tempStr);
            }
          });
        })
        .catch(err => console.error(err));
    },
    getFormData() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      this.perm_id = props.perm_id;
      this.form_id = props.form_id;
      productDesignGroupAPI.get_designTeam_id({ designTeam_id: props.form_id }).then(({ data }) => {
        this.btn = data.btn;
        this.designTeam_id = data.form.designTeam_id;
        this.formStatus = data.form.status;
        this.mainForm.formProperties.forEach(item => (item.model = data.form[item.prop]));
        this.subTableData = data.form.designTeamDetailsList;
        this.subFileTableData = data.form.documentPointerList;
        changeStaffForm(this.stffForm, data.form);
      });
    },
    saveInfo() {
      productDesignGroupAPI.edit_designTeam(this.getSaveData()).then(({ data }) => {
        this.uploadingFiles = [];
        this.$message.success('保存成功!');
        this.isShow = true;
        this.$emit('isShow', this.isShow);
        this.initData();
      });
    },
    getSaveData() {
      let formModel = this.$refs.dynamicForm.getFormModel();
      formModel.designTeam_id = this.designTeam_id;
      formModel.designTeamDetailsList = this.subTableData;
      formModel.documentPointerList = this.subFileTableData;
      return formModel;
    },
    delRow1() {
      if (this.subSelections.length === 0) return this.$message.warning('请选择数据!');
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.subSelections.forEach(item => (item.destroy_flag = 1));
        })
        .catch(err => console.error(err));
    },
    delRow2() {
      if (this.subFileSelections.length === 0) return this.$message.warning('请选择数据!');
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.subFileSelections.forEach(item => (item.destroy_flag = 1));
        })
        .catch(err => console.error(err));
    },
    importManuscript() {
      this.productDesignGroupDialog2Visible = true;
    },
    relatedProductDesignForm() {
      this.productDesignGroupDialog1Visible = true;
    },
    getProductDesignGroupDialog1Data() {
      let searchForm = cloneDeep(this.$refs.productDesignGroupDialog1.searchForm);
      setTime(searchForm, 'startTime', 'endTime', this.dialog1DataRange || [], true);
      productDesignGroupAPI.get_designList(searchForm).then(({ data }) => {
        this.productDesignGroupDialog1TableData = data.list;
        this.productDesignGroupDialog1Total = data.total;
      });
    },
    productDesignGroupDialog1Confirm() {
      if (this.productDesignGroupDialog1Selections.length === 0) return this.$message.warning('请选择数据!');
      this.productDesignGroupDialog1Selections.forEach(item => {
        if (!this.subTableData.find(x => x.desi_id === item.desi_id && !x.destroy_flag)) {
          this.subTableData.push(item);
          this.subFileTableData = this.subFileTableData.concat(item.documentPointerList);
        }
      });
      this.$refs.productDesignGroupDialog1.clearSelection();
      this.productDesignGroupDialog1Visible = false;
    },
    productDesignGroupDialog1Refresh() {
      this.dialog1DataRange = [];
      this.$refs.productDesignGroupDialog1.resetFields();
      this.$refs.productDesignGroupDialog1.clearSelection();
      this.getProductDesignGroupDialog1Data();
    },
    getProductDesignGroupDialog2Data() {
      let searchForm = cloneDeep(this.$refs.productDesignGroupDialog2.searchForm);
      setTime(searchForm, 'startTime', 'endTime', this.dialog2DataRange || [], true);
      searchForm.dequ_ids = arrayDeRepeat(getArrayIds(this.subTableData, 'dequ_id')).toString();
      productDesignGroupAPI.get_docuList(searchForm).then(({ data }) => {
        this.productDesignGroupDialog2TableData = data.list;
      });
    },
    productDesignGroupDialog2Confirm() {
      if (this.productDesignGroupDialog2Selections.length === 0) return this.$message.warning('请选择数据!');
      this.subFileTableData = this.subFileTableData.concat(this.productDesignGroupDialog2Selections);
      this.$refs.productDesignGroupDialog2.clearSelection();
      this.productDesignGroupDialog2Visible = false;
    },
    productDesignGroupDialog2Refresh() {
      this.dialog2DataRange = [];
      this.$refs.productDesignGroupDialog2.resetFields();
      this.$refs.productDesignGroupDialog2.clearSelection();
      this.getProductDesignGroupDialog2Data();
    },
    beforeUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 300;
      if (!isLt5M) {
        this.$message.error('文件大小不得超过 300MB!');
      }
      if (this.subFileTableData.find(x => x.docu_name + x.docu_suffix === file.name)) {
        this.message();
        return false;
      }
      if (this.uploadingFiles.includes(file.name)) {
        this.message();
        return false;
      }
      this.uploadingFiles.push(file.name);
      this.beforeFlag++;
      return isLt5M;
    },
    uploadSuccess(res) {
      if (res.code === 0) {
        res.data.uploaded_personnel = this.$cookies.get('userInfo').stff_name;
        this.subFileTableData.push(res.data);
      } else {
        this.$message.warning('该文件类型不支持');
      }
      this.successFlag++;
      if (this.successFlag === this.beforeFlag) {
        setTimeout(() => {
          this.$refs.upload.clearFiles(); //去掉文件列表
          this.successFlag = 0;
          this.beforeFlag = 0;
        }, 500);
      }
    },
    message: debounce(function () {
      this.$message.error('相同文件不得重复上传！');
    }, 1000),
    clickDownload(row) {
      this.$confirm('确定下载?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.docuUrl = this.helper.megPath(row.docu_url);
          this.docuName = row.docu_name + '.' + row.docu_suffix;
          this.helper.downloadItemC(this.docuUrl, this.docuName);
        })
        .catch(() => {});
    },
    clickView(row) {
      this.imageDialogVisible = true;
      this.showImgUrl = this.helper.megPath(row.docu_url);
    },
    async openPDF(url) {
      window.open(helper.megPath(url));
    },
    openOffice(url) {
      window.open('https://view.officeapps.live.com/op/view.aspx?src=' + helper.megPath(url));
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-upload {
  width: auto;
  height: auto;
  border: none;
  border-radius: 0;
}
</style>
